import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://9a6f2e2dd0324a6e898b1a275d0afb88@o510915.ingest.sentry.io/5607267',
  environment: devMode ? 'local' : 'production',
  release: devMode ? 'local' : 'bc5b109d',
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['healthlabs.com', 'www.healthlabs.com'],
    }),
  ],
  maxBreadcrumbs: 50,
  sampleRate: devMode ? 0 : 1,
  tracesSampleRate: devMode ? 0 : 0.01,
});
